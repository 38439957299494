:root {
  /* Colors */
  --magenta: #bc00ff;
  --black: #000;
  --background-grey: #22292f;

  /* The Tailwind CSS greys are lovely */
  --grey-100: #f5f5f5;
  --grey-200: #eeeeee;
  --grey-300: #e0e0e0;
  --grey-400: #bdbdbd;
  --grey-500: #9e9e9e;
  --grey-600: #757575;
  --grey-700: #616161;
  --grey-800: #424242;
  --grey-900: #212121;

  /* Sizes */
  --text-sm: 0.5em;
  --text-m: 0.8em;
  --text-l: 1.2em;
  --text-xl: 1.5em;
  --text-2xl: 2em;
  --text-3xl: 2.5em;
  --text-4xl: 3em;
  --text-5xl: 3.5em;
  --text-very-large: 5em;

  /* Spacing */
  --spacing-1: 8px;
  --spacing-2: 12px;
  --spacing-3: 16px;
  --spacing-4: 24px;
  --spacing-5: 32px;
  --spacing-6: 48px;
  --spacing-7: 64px;

  /* Song Info Sizing */
  --song-info-min-sizing: 350px;
}

html {
  height: 100%;
}

body {
  background-color: var(--background-grey);
  font-size: 16px;
  overflow-x: hidden;
}

.lyricsContainer p {
  color: #b8c2cc;
  font-size: 1.125rem;
  line-height: 1.4;
}

::-moz-selection {
  /* @apply bg-magenta text-black; */
  background: var(--magenta);
  color: var(--black);
}

::selection {
  background: var(--magenta);
  color: var(--black);
}

::-moz-selection {
  background: var(--magenta);
  color: var(--black);
}

.list-reset {
  list-style: none;
  padding: 0;
}
